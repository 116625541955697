import useMyPlans from 'business-dashboard/src/hooks/useMyPlans';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@chakra-ui/react';

import client from 'business-dashboard/src/services/client';
import useProperty from 'business-dashboard/src/components/useProperty';
import propertyResolver from 'business-dashboard/src/services/propertyResolver';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import usePagedList from 'shared/src/hooks/usePagedList';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import useCheckedPlans from 'shared/src/hooks/useCheckedPlans';

import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';

import NoOffersLocationsTable from './NoOffersLocationsTable';
import OffersViewWrapper from '../Offers/OffersViewWrapper';

import ConditionalWrapper from '../../ConditionalWrapper';

const fetchLocations = ({ query, businessId, checkedPlanIds, itemsPerPage }) => {
  const property = propertyResolver();
  return client
    .properties.for(property.id)
    .businesses.for(businessId)
    .locations.list({
      query,
      hasRoles: true,
      accessContext: 'implicit',
      hasLiveOffers: false,
      limit: itemsPerPage,
      planIds: checkedPlanIds.length ? checkedPlanIds : undefined,
    })
    .catch((error) => {
      // UI will show error message but log the error to the console for debugging
      /* eslint-disable-next-line no-console */
      console.error(error);
      return Promise.reject(error);
    });
};

const NoOffersLocations = ({ businessId, itemsPerPage }) => {
  const property = useProperty();
  const plans = useMyPlans(property);
  const { strings } = useI18Next();

  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const [checkedPlans, handleCheckedPlans, handleUnCheckedPlan, checkedPlanIds] = useCheckedPlans(plans);

  const locationsList = usePagedList(fetchLocations, { query, businessId, checkedPlanIds, itemsPerPage });
  const business = locationsList.items?.[0]?.business;

  const breadcrumbs = [{
    to: '/dashboard',
    title: strings('ui.component.appSidebar.dashboard')
  }, {
    to: '/dashboard/no-offers',
    title: strings('owners.widget.locationsWithoutOffers.locationsWithoutOffers'),
  }, {
    to: `/dashboard/no-offers/${businessId}`,
    title: business?.name,
    isCurrentPage: true
  }];

  const ViewWrapper = children => (
    <OffersViewWrapper
      breadcrumbs={breadcrumbs}
      plans={plans}
      checkedPlans={checkedPlans}
      handleDebounceQuery={debouncedQueryHandler}
      handleCheckedPlans={handleCheckedPlans}
      handleUnCheckedPlan={handleUnCheckedPlan}
      query={query}
      title={business?.name}
      showPlanFilter={false}
    >
      {children}
    </OffersViewWrapper>
  );

  return (
    <ConditionalWrapper condition={true} wrapper={ViewWrapper}>
      <Flex flexDir='column' border='1px' layerStyle='widgetBorderColor'>
        <NoOffersLocationsTable
          business={locationsList.items.length && locationsList.items[0]?.business}
          locations={locationsList.items}
          isLoading={locationsList.loading}
          itemsPerPage={itemsPerPage}
          noOffersString={strings('owners.widget.locationsWithoutOffers.emptyStateLocations')}
        />
      </Flex>
      <Box paddingY='24px'>
        <AutoPagination pagedList={locationsList} />
      </Box>
    </ConditionalWrapper>
  );
};

NoOffersLocations.propTypes = {
  businessId: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired
};

export default NoOffersLocations;
