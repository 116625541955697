/* eslint object-curly-newline: 0, class-methods-use-this: 0, arrow-body-style: 0 react/prop-types: 0 */
import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import auth from 'shared/src/modules/auth';

const PrivateRoute = ({ component: RouteComponent, render, ...rest }) => {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector(state => auth.selectors.getIsLoggedIn(state));
  const isPending = useSelector(state => auth.selectors.isPending(state));

  const redirectToLogin = () => {
    const redirectTo = encodeURIComponent(location.pathname + location.search);
    const redirectPath = `/login?redirect_to=${redirectTo}`;
    history.push(redirectPath);
  };

  return (
    <Route
      {...rest}
      history={history}
      location={location}
      render={(props) => {
        if (isPending) return <span>Loading...</span>;
        if (isAuthenticated) return render ? render(props) : <RouteComponent {...props} />;

        redirectToLogin();
        return null;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func
};

export default PrivateRoute;
