import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Dimmer from 'web-react-ui/src/components/modules/Dimmer';
import Loader from 'web-react-ui/src/components/elements/Loader';

import auth from 'shared/src/modules/auth';

const InitialState = ({ children }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(state => auth.selectors.isPending(state));

  useEffect(() => {
    dispatch(auth.actions.refresh());
  }, []);

  if (isPending) {
    return (
      <Dimmer.Dimmable as={'div'} dimmed style={{ height: '100vh' }}>
        <Dimmer simple inverted />
        <Loader active />
      </Dimmer.Dimmable>
    );
  }
  return children;
};

InitialState.propTypes = {
  children: PropTypes.node
};

export default InitialState;
