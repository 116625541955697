import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const options = {
  plugins: {
    legend: {
      display: false
    }
  }
};

const DoughnutChart = ({ data }) => (
  <Doughnut data={data} options={options} />
);

DoughnutChart.propTypes = {
  data: PropTypes.object
};

export default React.memo(DoughnutChart);
