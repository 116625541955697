/* eslint-disable-next-line no-redeclare */
/* global document */
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import UiClientContext from 'shared/src/components/contexts/ClientContext';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import chakraTheme from 'web-react-ui/src/chakra-theme';
import 'web-react-ui/src/css';

import * as i18Next from 'business-dashboard/src/i18n';

import App from './App';

import registerServiceWorker from './registerServiceWorker';
import client from './services/client';
import store from './store/store';

import './index.css';

ReactDOM.render(
  <ReduxProvider store={store}>
    <UiClientContext.Provider value={client}>
      <I18NextContext.Provider value={i18Next}>
        <ChakraProvider theme={chakraTheme}>
          <App />
        </ChakraProvider>
      </I18NextContext.Provider>
    </UiClientContext.Provider>
  </ReduxProvider>,
  document.getElementById('root')
);

registerServiceWorker();
