import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import List from 'web-react-ui/src/chakra/List';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const MobileLocationsList = ({ businesses }) => {
  const history = useHistory();
  const { strings } = useI18Next();
  return (
    <>
      {businesses.settled && businesses.items?.map((business) => {
        const locationString = business?.locations?.length === 1 ?
          strings('owners.widget.locationsWithoutOffers.location') :
          strings('owners.widget.locationsWithoutOffers.locations');
        return (
          <List.Item
            key={business.id}
            onClickHandler={() => history.push(`/business/${business.id}`)}
          >
            <ProfileImage imageId={business.logoImage} name={business.name} mr='16px' />
            <Box>
              <List.Header isTruncated>{business.name}</List.Header>
              <List.Body>
                {`${business?.locations?.length} ${locationString}`}
              </List.Body>
              <List.Footnote>{business.plan.name}</List.Footnote>
            </Box>
          </List.Item>
        );
      })}
      {
        businesses.loading && (
          <Flex px='24px' py='12px' w='100%'>
            <Flex mr='16px'>
              <SkeletonCircle h='32px' w='32px' />
            </Flex>
            <SkeletonText noOfLines={3} height='50px' w='100%' />
          </Flex>
        )
      }
    </>
  );
};

MobileLocationsList.propTypes = {
  businesses: PropTypes.shape({
    loading: PropTypes.bool,
    settled: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      locations: PropTypes.array,
      plan: PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    }))
  })
};

export default MobileLocationsList;
