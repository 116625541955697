import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Skeleton,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody
} from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import NoOffersLocationsListItem from './NoOffersLocationsListItem';

const NoOffersLocationsTable = ({ locations, isLoading, noOffersString, itemsPerPage }) => {
  const { strings } = useI18Next();

  const hasLocations = Array.isArray(locations) && locations?.length;

  if (!isLoading && !hasLocations) {
    return (
      <Flex align="center" justify="center" minH="300px">
        <Text color="gray.400">
          {noOffersString}
        </Text>
      </Flex>
    );
  }

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>{strings('owners.widget.locationsWithoutOffers.locations')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {isLoading && !hasLocations && (
          Array(itemsPerPage).fill(null).map((item, index) => (
            <Tr key={`NoOffersLocationsTable-${index}`}><Td colSpan={5}><Skeleton h="50px" w="100%" /></Td></Tr>
          ))
        )}
        {hasLocations && locations.map(item => (
          <NoOffersLocationsListItem key={item.id} business={locations[0].business} location={item} />
        ))}
      </Tbody>
    </Table>
  );
};

NoOffersLocationsTable.propTypes = {
  locations: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  noOffersString: PropTypes.string,
};

export default NoOffersLocationsTable;
