import useMyPlans from 'business-dashboard/src/hooks/useMyPlans';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Spacer,
  Box
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import {
  offerListViewParamsFactory,
  VIEW_TYPES
} from 'shared/src/helpers/offerListViewParams';
import usePagedList from 'shared/src/hooks/usePagedList';
import useCheckedPlans from 'shared/src/hooks/useCheckedPlans';

import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';

import client from 'business-dashboard/src/services/client';
import useProperty from 'business-dashboard/src/components/useProperty';
import propertyResolver from 'business-dashboard/src/services/propertyResolver';
import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';
import Offer from 'web-react-ui/src/types/Offer.interface';

import OffersViewWrapper from './OffersViewWrapper';
import ConditionalWrapper from '../../ConditionalWrapper';

const fetchOffers = ({ query, checkedPlanIds, itemsPerPage, hasCheckedPlans }) => {
  if (!hasCheckedPlans) {
    return null;
  }

  const property = propertyResolver();
  return client
    .properties.for(property.id)
    .offers.list({
      query,
      limit: itemsPerPage,
      hasRoles: true,
      planIds: checkedPlanIds.length ? checkedPlanIds : undefined,
      ...offerListViewParamsFactory(VIEW_TYPES.expiring)
    });
};

const buildHref = offer => `/business/${offer.businessId}/offers/${offer.id}`;

const ExpiringOffers = ({ itemsPerPage = 5 }) => {
  const { strings } = useI18Next();
  const property = useProperty();
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const plans = useMyPlans(property);
  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const [
    checkedPlans,
    handleCheckedPlans,
    handleUnCheckedPlan,
    checkedPlanIds,
    hasCheckedPlans
  ] = useCheckedPlans(plans);
  const offersList = usePagedList(fetchOffers, { query, checkedPlanIds, itemsPerPage, hasCheckedPlans });
  const handleDebounceQuery = q => debouncedQueryHandler(q);

  const breadcrumbs = [{
    to: '/dashboard',
    title: strings('ui.component.appSidebar.dashboard')
  }, {
    to: '/dashboard/expiring',
    title: strings('owners.widget.offers.recentlyExpiredOffers'),
    isCurrentPage: true
  }];

  const ViewWrapper = children => (
    <OffersViewWrapper
      breadcrumbs={breadcrumbs}
      plans={plans}
      checkedPlans={checkedPlans}
      handleDebounceQuery={handleDebounceQuery}
      handleCheckedPlans={handleCheckedPlans}
      handleUnCheckedPlan={handleUnCheckedPlan}
      query={query}
      title={strings('owners.widget.offers.offersExpiringSoon')}
    >
      {children}
    </OffersViewWrapper>
  );

  return (
    <ConditionalWrapper condition={!isDashboard} wrapper={ViewWrapper}>
      <Flex flexDir="column" border={!isDashboard && '1px'} layerStyle="widgetBorderColor">
        <OffersListTable
          offersList={offersList}
          noOffersString={strings('owners.widget.offers.noOffersExpiringSoon')}
          buildHref={buildHref}
          maxBodyHeight='400px'
        />
      </Flex>
      <Spacer />
      <Box py={6} px={isDashboard && 6} borderTop={isDashboard && '1px'} borderColor="gray.200">
        <AutoPagination pagedList={offersList} />
      </Box>
    </ConditionalWrapper>
  );
};

ExpiringOffers.propTypes = {
  itemsPerPage: PropTypes.number
};

export default ExpiringOffers;
