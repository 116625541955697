import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Link } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import WidgetHeader from './WidgetHeader';

const WidgetWrapper = ({ headerTitle, path, children }) => {
  const { strings } = useI18Next();

  return (
    <Flex
      flexDirection='column'
      w='100%'
      border='1px'
      borderRadius='12px'
      borderColor='gray.200'
    >
      <WidgetHeader
        title={headerTitle}
        headerLink={
          <Link
            as={ReachLink}
            to={path}
            padding='10px'
            whiteSpace='nowrap'
            border='1px'
            rounded='6px'
            fontWeight={600}
            _hover={{
              background: 'gray.100',
              color: 'black',
              textDecoration: 'none'
            }}
            layerStyle='widgetBorderColor'
          >
            {strings('owners.widget.actions.viewAll')}
          </Link>
        }
      />
      {children}
    </Flex>
  );
};

WidgetWrapper.propTypes = {
  headerTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
};

const Widget = WidgetWrapper;
export default Widget;

