import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import SearchInput from 'web-react-ui/src/chakra/SearchInput';
import PlanFilter from 'business-dashboard/src/components/PlanFilter';

const ViewWrapper = ({
  children,
  title,
  breadcrumbs,
  checkedPlans,
  plans,
  handleDebounceQuery,
  handleCheckedPlans,
  handleUnCheckedPlan,
  query,
  showPlanFilter = true
}) => {
  const { strings } = useI18Next();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <Box>
      <Breadcrumbs items={breadcrumbs} />
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        align={{ base: 'flex-start', lg: 'center' }}
        justify={{ lg: 'space-between' }}
      >
        <ViewHeader title={title}>
          {showPlanFilter && !isSmallScreen && (
            <PlanFilter
              plans={plans}
              checkedPlans={checkedPlans}
              handleRemoveTag={handleUnCheckedPlan}
              handleCheckedPlans={handleCheckedPlans}
              handleUnCheckedPlan={handleUnCheckedPlan}
              onClose={onClose}
              onOpen={onOpen}
              isOpen={isOpen}
            />
          )}
        </ViewHeader>
      </Flex>
      <Box mb={{ base: '16px', lg: '32px' }}>
        <SearchInput
          defaultValue={query}
          onChange={handleDebounceQuery}
          placeholder={strings('owners.widget.locationsWithoutOffers.input.placeholder.search')}
        />
      </Box>
      {showPlanFilter && isSmallScreen && (
        <Box mt='16px'>
          <PlanFilter
            plans={plans}
            checkedPlans={checkedPlans}
            handleRemoveTag={handleUnCheckedPlan}
            handleCheckedPlans={handleCheckedPlans}
            handleUnCheckedPlan={handleUnCheckedPlan}
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
          />
        </Box>
      )}
      {children}
    </Box>
  );
};

ViewWrapper.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
    isCurrentPage: PropTypes.bool,
  })),
  plans: PropTypes.object,
  handleDebounceQuery: PropTypes.func,
  query: PropTypes.string,
  checkedPlans: PropTypes.array,
  handleCheckedPlans: PropTypes.func.isRequired,
  handleUnCheckedPlan: PropTypes.func.isRequired,
  showPlanFilter: PropTypes.bool
};

export default ViewWrapper;
