import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Tr,
  Td,
  Flex,
  Box,
  Text,
  Avatar
} from '@chakra-ui/react';

const NoOffersLocationsListItem = ({ business, location }) => (
  <Tr>
    <Td width={[255, '80%']} maxWidth={[255, '80%']}>
      <Link to={`/business/${business.id}/locations/${location.id}`}>
        <Flex alignItems='center'>
          <Avatar src={business.logoStandardImages[0].url} name={business.name} mr='8px' />
          <Box maxWidth='100%'>
            <Text layerStyle='widgetContentText'>{location.name}</Text>
            <Text layerStyle='widgetContentSubText'>
              {location.address.label}
            </Text>
          </Box>
        </Flex>
      </Link>
    </Td>
  </Tr>
);

NoOffersLocationsListItem.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logoStandardImages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    )
  }),
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    address: PropTypes.shape({
      label: PropTypes.string
    })
  })
};

export default NoOffersLocationsListItem;
