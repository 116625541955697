/* eslint-env browser */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { parse } from 'mini-querystring';

import usePromise from 'shared/src/hooks/usePromise';
import auth from 'shared/src/modules/auth';

import Shell from 'web-react-ui/src/chakra/shell/Shell';
import AuthExpiry from 'web-react-ui/src/components/auth/AuthExpiry';
import AuthReceiver from 'web-react-ui/src/components/auth/AuthReceiver';
import { ToastContainer } from 'web-react-ui/src/components/toasts';
import IsLoggedInRedirect from 'web-react-ui/src/components/login/IsLoggedInRedirect';

import AppSidebar from 'business-dashboard/src/AppSidebar';
import LoginView from 'business-dashboard/src/components/login/LoginView';
import BusinessSelectView from 'business-dashboard/src/components/businesses/BusinessSelectView';
import BusinessDetailsView from 'business-dashboard/src/components/businesses/BusinessDetailsView';
import RegisterView from 'business-dashboard/src/components/businesses/RegisterView';
import RegisterComplete from 'business-dashboard/src/components/businesses/RegisterComplete';
import AddBusinessView from 'business-dashboard/src/components/businesses/AddBusinessView';
import Preferences from 'business-dashboard/src/components/Preferences';
import { usePropertyDispatch, usePropertyState } from 'business-dashboard/src/hooks/useProperty';
import businessesModule from 'business-dashboard/src/modules/businesses';

import RecentlyExpiredOffers from './components/OwnerDashboard/Offers/RecentlyExpiredOffers';
import ExpiringOffers from './components/OwnerDashboard/Offers/ExpiringOffers';
import OwnerDashboard from './components/OwnerDashboard';
import InitialState from './components/InitialState';
import PrivateRoute from './components/PrivateRoute';

import NoOffersLocations from './components/OwnerDashboard/NoOffers/NoOffersLocations';
import NoOffersBusinesses from './components/OwnerDashboard/NoOffers/NoOffersBusinesses';

import client from './services/client';

import gitInfo from './.git-info.json';

import './App.css';

const App = () => {
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const isLoggedIn = useSelector(state => auth.selectors.getIsLoggedIn(state));
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const { property } = usePropertyState();
  const { setProperty, setPropertyFlag } = usePropertyDispatch();

  // ie. isPlanViewable = isGitlProperty
  // Can always be true because it will only ever be 'getintheloop' property(s)
  const isPlanViewable = true;

  const [, , businessProfileImage] = usePromise(
    () => {
      if (!business) {
        return undefined;
      }

      return client.images.for(business.profileImage).details();
    },
    [business],
    undefined,
    true
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info(`${gitInfo.name} v${gitInfo.version} (build ${gitInfo.hash})`);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setProperty();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (property !== null) {
      setPropertyFlag(property.subdomain);
    }
  }, [property]);

  return (
    <AuthReceiver sitePermission='*user.owner.access'>
      <ToastContainer />
      <InitialState>
        <Router>
          <AuthExpiry />
          <Shell
            title={business?.name}
            alertMessage={process.env.REACT_APP_GLOBAL_ALERT_MESSAGE}
            alertStatus={process.env.REACT_APP_GLOBAL_ALERT_STATUS}
          >
            <Shell.Menu>
              <Switch>
                <Route>
                  <AppSidebar
                    key={forceUpdate}
                    isOwner={true}
                    title={business?.name}
                    logo={businessProfileImage?.url}
                  />
                </Route>
              </Switch>
            </Shell.Menu>
            <Shell.Body>
              <Switch>
                <Route
                  path='/login'
                  render={() => (isLoggedIn ? <IsLoggedInRedirect /> : <LoginView dashboard={false} />)}
                />
                <PrivateRoute path='/dashboard' exact component={OwnerDashboard} />
                <PrivateRoute
                  path='/businesses/register/complete/:businessId'
                  exact={true}
                  render={() => <RegisterComplete property={property} />}
                />
                <PrivateRoute
                  path='/businesses/register'
                  exact={true}
                  render={() => <RegisterView isPlanViewable={isPlanViewable} />}
                />
                <PrivateRoute
                  path='/businesses'
                  exact={true}
                  render={({ location }) => {
                    const skipRedirect = parse(location.search)['no-redirect'];
                    return (
                      <BusinessSelectView
                        skipRedirect={skipRedirect}
                        isOwner={true}
                      />
                    );
                  }}
                />
                <PrivateRoute
                  path='/businesses/add'
                  exact={true}
                  render={() => (
                    <AddBusinessView property={property} isPlanViewable={isPlanViewable} />
                  )}
                />
                <PrivateRoute
                  path='/business/:businessSlug'
                  render={(props) => {
                    const businessSlug = _.get(props, 'match.params.businessSlug');
                    return (
                      <BusinessDetailsView businessId={businessSlug} isPlanViewable={isPlanViewable} isOwner={true} />
                    );
                  }}
                />
                <PrivateRoute path='/dashboard/offers/expired'>
                  <RecentlyExpiredOffers itemsPerPage={20} />
                </PrivateRoute>
                <PrivateRoute path='/dashboard/offers/expiring'>
                  <ExpiringOffers itemsPerPage={20} />
                </PrivateRoute>
                <PrivateRoute
                  path='/dashboard/no-offers/:businessId'
                  exact={true}
                  render={({ match }) => {
                    return (
                      <NoOffersLocations businessId={match.params.businessId} itemsPerPage={20} />
                    );
                  }}
                />
                <PrivateRoute path='/dashboard/no-offers' exact={true}>
                  <NoOffersBusinesses itemsPerPage={20} />
                </PrivateRoute>
                <PrivateRoute path='/preferences'>
                  <Preferences dashboard={false} setForceUpdate={setForceUpdate} />
                </PrivateRoute>
                <Redirect to='/dashboard' />
              </Switch>
            </Shell.Body>
          </Shell>
        </Router>
      </InitialState>
    </AuthReceiver>
  );
};

export default App;
