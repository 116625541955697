import {
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  StackDivider,
  Text,
  useBreakpointValue,
  useMediaQuery,
  useTheme,
  VStack
} from '@chakra-ui/react';
import useProperty from 'business-dashboard/src/components/useProperty';

import client from 'business-dashboard/src/services/client';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useRequest from 'shared/src/hooks/useRequest';
import ErrorBoundary from 'web-react-ui/src/chakra/ErrorBoundary';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import DoughnutChart from './widgets/DoughnutChart';

const backgroundColor = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink'
];

const LoadingComponent = () => (
  <React.Fragment>
    <SimpleGrid
      columns={[1, null, 2]}
      spacing='24px'
      borderTop='1px'
      layerStyle={['widgetPadding', 'widgetBorderColor']}
    >
      <Flex align='center' justifyContent='start'>
        <Box w='200px' h='200px'>
          <SkeletonCircle h='200px' w='200px' />
        </Box>
      </Flex>
      <VStack spacing='16px' align='stretch'>
        <Skeleton w='100%' h='24px' />
        <Skeleton w='100%' h='24px' />
      </VStack>
    </SimpleGrid>
    <VStack divider={<StackDivider layerStyle='widgetBorderColor' />} align='stretch'>
      <VStack spacing='16px' paddingX='24px' paddingY='14px'>
        {Array(4).fill(null).map((item, index) => (
          <Skeleton key={index} w='100%' h='52px' />
        ))}
      </VStack>
      <Flex justifyContent='space-between' py={6} px={6} borderColor='gray.200'>
        <Skeleton w='100%' h='24px' />
      </Flex>
    </VStack>
  </React.Fragment>
);

const fetchBusinessPlanSummary = ({ strings, theme }) => async ({ property }) => {
  if (!property) return null;
  const result = await client.properties.for(property.id).getBusinessPlanSummary();
  const businessesTotal = result?.plans?.reduce((prev, acc) => prev + acc.count, 0);

  // ['GITL Local Monthly Plan', 'GITL Monthly Enterprise Plan', 'etc']
  const dataLabels = result?.plans?.map((plan => plan.name));

  // [25, 7, 39, 2]
  const dataValues = result?.plans?.map((plan) => {
    return Math.round((plan.count / businessesTotal) * 100);
  });

  const data = {
    labels: [...dataLabels],
    datasets: [{
      label: strings('owners.widget.businessSummary.graph.label'),
      data: dataValues,
      backgroundColor: backgroundColor.map(color => theme.colors[color][300])
    }]
  };

  return {
    graphData: data,
    summary: result
  };
};

const BusinessText = ({ count }) => {
  const { strings } = useI18Next();
  return count ? (
    <Text layerStyle='widgetContentText'>
      {count}&nbsp;
      {strings(`owners.widget.businessSummary.business${count !== 1 ? 'es' : ''}`)}
    </Text>
  ) : null;
};

BusinessText.propTypes = {
  count: PropTypes.number
};

const CountDetails = ({ count, data }) => {
  const textAlign = useBreakpointValue({ base: 'right', md: 'left' });
  return (
    <HStack spacing='8px' justifyContent='space-between'>
      <BusinessText count={count} />
      <Text layerStyle='widgetContentText' textAlign={textAlign}>
        {data}%
      </Text>
    </HStack>
  );
};

CountDetails.propTypes = {
  count: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const PlanData = ({ item, bg, data, handleClick }) => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  return (
    <SimpleGrid
      key={item.id}
      columns={[1, null, 2]}
      alignItems='center'
      spacing='24px'
      paddingX='24px'
      paddingY='14px'
      _hover={{ cursor: 'pointer' }}
      onClick={() => handleClick(item.id)}
    >
      <HStack spacing='8px' w='100%'>
        <Box
          minW='24px'
          minH='24px'
          rounded='6px'
          bg={bg}
        />
        <Box w='100%'>
          <Text layerStyle='widgetContentText'>{item.name}</Text>
          {!isLargerThan1280 && (
            <CountDetails count={item.count} data={data} />
          )}
        </Box>
      </HStack>
      {isLargerThan1280 && (
        <CountDetails count={item.count} data={data} />
      )}
    </SimpleGrid>
  );
};

PlanData.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number
  }),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bg: PropTypes.string,
  handleClick: PropTypes.func
};

const BusinessPlanSummary = () => {
  const { strings } = useI18Next();
  const property = useProperty();
  const theme = useTheme();
  const history = useHistory();
  const summaryRequest = useRequest(fetchBusinessPlanSummary({ strings, theme }), { property });
  const { result } = summaryRequest || {};
  const { summary, graphData } = result || {};

  const handleRowClick = (locationId) => {
    history.push({ pathname: `/businesses`, state: { planId: locationId } });
  };

  if (!summaryRequest.settled) {
    return <LoadingComponent />;
  }

  if (summaryRequest.error) {
    return (
      <Box p={4}>
        <ErrorMessage error={summaryRequest.error} />
      </Box>
    );
  }

  // TODO Needs an Empty State
  return (
    <React.Fragment>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing='24px'
        layerStyle='widgetPadding'
      >
        <Flex align='center' justifyContent='start'>
          <Box w='200px' h='200px' overflow='auto'>
            <ErrorBoundary message={'Sorry, we can\'t render this chart on your device!'}>
              <DoughnutChart data={graphData} />
            </ErrorBoundary>
          </Box>
        </Flex>
        <VStack spacing='16px' align='stretch'>
          {summary?.businessCount && (
            <Box>
              <Text layerStyle='widgetContentText'>
                {strings('owners.widget.businessSummary.totalEnabledBusinesses')}
              </Text>
              <Text layerStyle='widgetContentCallout'>
                {summary.businessCount}
              </Text>
            </Box>
          )}
          {summary?.locationCount && (
            <Box>
              <Text layerStyle='widgetContentText'>
                {strings('owners.widget.businessSummary.totalEnabledLocations')}
              </Text>
              <Text layerStyle='widgetContentCallout'>
                {summary.locationCount}
              </Text>
            </Box>
          )}
        </VStack>
      </SimpleGrid>
      <VStack divider={<StackDivider layerStyle='widgetBorderColor' />} align='stretch'>
        {summary?.plans?.map((item, index) => (
          <PlanData
            key={item.id}
            item={item}
            data={graphData?.datasets?.[0]?.data?.[index]}
            handleClick={handleRowClick}
            bg={`${backgroundColor[index]}.300`}
          />
        ))}
        {summary?.businessCount && (
          <Flex justifyContent='space-between' py={6} px={6} borderColor='gray.200'>
            <BusinessText count={summary.businessCount} />
          </Flex>
        )}
      </VStack>
    </React.Fragment>
  );
};

export default BusinessPlanSummary;
