import { Flex } from '@chakra-ui/react';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import Message from 'web-react-ui/src/components/collections/Message';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import PLAN_ACTIONS from 'web-react-ui/src/components/PlanCards2/planActions';
import PlanCardLayout from 'web-react-ui/src/components/PlanCards2/PlanCardLayout';
import {
  BillingPlan,
  Plan,
} from 'web-react-ui/src/components/PlanCards2/types';
import usePlanController from 'web-react-ui/src/components/PlanCards2/usePlanController';
import Business from 'web-react-ui/src/types/Business.interface';
import plans from '../../../assets/data/plans';
import { strings } from '../../../i18n';
import confirm from '../../../services/confirm';
import BillingPeriodSelector from './BillingPeriodSelector';
import ErrorView from './ErrorView';

const PlansView = (
  { business, billingPlan, openCheckout }:
    { business: Business, billingPlan: BillingPlan, openCheckout: Function },
) => {
  const [error, setError] = useState<Error | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const [urlCbPlanId, setUrlCbPlanId] = useQuerystringParam('planId', undefined, { squash: true });
  const [autoPlanId, autoBillingPeriod] = (urlCbPlanId || '').split('_');

  const [planController] = usePlanController({
    basePlans: plans,
    // If there's no subscription, pretend we're on Community so we can "upgrade" to anything...
    billingPlan: billingPlan || { itemPriceId: 'community-monthly-cad' },
  });


  const doCheckout = useMemo(
    () => {
      if (!openCheckout) return null;
      // eslint-disable-next-line consistent-return
      return (plan: Plan) => {
        if (plan.action !== PLAN_ACTIONS.upgrade) return null;

        const result = confirm(strings('dashboard.screen.plansView.confirmPrompt'));
        if (!result) {
          setUrlCbPlanId(null);
          planController?.setSelectedPlan(null);
          return undefined;
        }

        setUrlCbPlanId(planController?.selectedPlanKey);

        // @ts-ignore
        openCheckout(
          { planId: plan.chargebeePlanId },
          {
            error: (err: Error) => setError(err),
            loaded: () => setError(null),
            close: () => {
              planController?.setSelectedPlan(null);
            },
            step: (step: any) => {
              if (step === 'thankyou_screen') {
                planController?.setBillingPlanId(plan.chargebeePlanId);
                setMessage(strings('dashboard.screen.plansView.success'));
              }
            },
          },
        );
      };
    },
    [openCheckout, planController],
  );

  // Hack around cyclic dependency between doCheckout and planController
  useEffect(
    () => {
      // @ts-ignore
      planController.setOnSelect(doCheckout);
    },
    [doCheckout, planController],
  );

  useEffect(
    () => {
      if (!autoPlanId || !planController) return;
      if (autoBillingPeriod === 'annual') planController.setPeriod(1, 'year');
      // Wait for render
      setTimeout(
        () => {
          planController.setSelectedPlanByKey(autoPlanId);
        },
        1000,
      );
    },
    [autoPlanId, planController],
  );

  if (planController.isLegacy) {
    return (
      <ErrorView
        title={strings('dashboard.screen.plansView.error.legacyBillingTitle')}
        message={strings('dashboard.screen.plansView.error.legacyBilling')}
      />
    );
  }

  return (
    <Flex direction="column" gap="2em" maxWidth="55em">
      <Flex flexDirection="column">
        {message && <Message positive>{message}</Message>}
        <ErrorMessage error={error?.message} />
      </Flex>
      <BillingPeriodSelector controller={planController} />
      <PlanCardLayout
        plans={planController?.plans}
        businessId={business.id}
        onSelect={(plan: Plan) => planController?.setSelectedPlan(plan)}
        isLocalOwnerDashboard={Boolean(process.env.REACT_APP_IS_OWNER)}
      />
    </Flex>
  );
};

export default PlansView;
