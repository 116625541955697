import {
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import MobileLocationsList from './MobileLocationsList';
import NoOffersBusinessesListItem from './NoOffersBusinessesListItem';

const NoOffersBusinessesTable = ({ businesses, noOffersString, itemsPerPage }) => {
  const { strings } = useI18Next();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  if (businesses.error) {
    return (
      <Box p={4}>
        <ErrorMessage error={businesses.error} />
      </Box>
    );
  }

  if (businesses.empty) {
    return (
      <Flex align="center" justify="center" minH="100px">
        <Text color="gray.400">
          {noOffersString}
        </Text>
      </Flex>
    );
  }

  return isSmallScreen ? (
    <MobileLocationsList businesses={businesses} />
  ) : (
    <Table style={{ tableLayout: 'fixed' }}>
      <Thead>
        <Tr>
          <Th>{strings('owners.widget.locationsWithoutOffers.business')}</Th>
          <Th>{strings('owners.widget.locationsWithoutOffers.plan')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {businesses.loading && (
          Array(itemsPerPage).fill(null).map((item, key) => (
            <Tr key={`noOffersBusiness-${key}`}>
              <Td width={[255, '80%']} maxWidth={[255, '80%']}>
                <Flex align="center">
                  <Flex mr="8px">
                    <SkeletonCircle h="32px" w="32px" />
                  </Flex>
                  <SkeletonText noOfLines={2} w="100%" />
                </Flex>
              </Td>
              <Td>
                <SkeletonText noOfLines={1} w="100%" />
              </Td>
            </Tr>
          ))
        )}
        {businesses.settled && businesses.items.map(item => (
          <NoOffersBusinessesListItem key={item.id} business={item} strings={strings} />
        ))}
      </Tbody>
    </Table>
  );
};

NoOffersBusinessesTable.propTypes = {
  businesses: PropTypes.shape({
    loading: PropTypes.bool,
    settled: PropTypes.bool,
    empty: PropTypes.bool,
    error: PropTypes.error,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      locations: PropTypes.array,
      plan: PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    }))
  }),
  noOffersString: PropTypes.string,
  itemsPerPage: PropTypes.number
};

export default NoOffersBusinessesTable;
