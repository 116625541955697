/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import { Text } from '@chakra-ui/react';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { message } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <Text>{message}</Text>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
