/* globals Beamer */
import React from 'react';
import { Box, Flex, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import BeamerTrigger from 'web-react-ui/src/chakra/BeamerTrigger';

import authModule from 'shared/src/modules/auth';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import QuickLinks from './QuickLinks';
import BusinessLinks from './BusinessLinks';
import Widget from './widgets/Widget';
import BusinessPlanSummary from './BusinessPlanSummary';
import RecentlyExpiredOffers from './Offers/RecentlyExpiredOffers';
import ExpiringOffers from './Offers/ExpiringOffers';
import NoOffersBusinesses from './NoOffers/NoOffersBusinesses';

const OwnerDashboard = (): JSX.Element => {
  const { strings } = useI18Next();
  const user = useSelector(state => authModule.selectors.getUser(state));
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  // since we're lazy-loading Beamer, we need to initialize it once it's defined
  // @ts-ignore
  if (typeof Beamer !== 'undefined' && !Beamer.initialized) Beamer.init();

  const welcomeString = user.name ?
    `${strings('ui.component.viewHeader.welcome')}, ${user.name}!` :
    `${strings('ui.component.viewHeader.welcome')}!`;

  return (
    <Box position='relative'>
      {!isSmallScreen && (
        <BeamerTrigger />
      )}
      <ViewHeader title={welcomeString} fontSize={{ base: '24px', md: '36px' }} />
      <Stack mt={8} pb={12} spacing={8}>
        <QuickLinks />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          <Widget
            headerTitle={strings('owners.widget.locationsWithoutOffers.locationsWithoutOffers')}
            path='dashboard/no-offers'
          >
            <NoOffersBusinesses />
          </Widget>
          <Widget
            headerTitle={strings('owners.widget.businessSummary.businessSummary')}
            path='/businesses'
          >
            <BusinessPlanSummary />
          </Widget>
        </SimpleGrid>
        <Flex>
          <Widget
            headerTitle={strings('owners.widget.offers.recentlyExpiredOffers')}
            path='dashboard/offers/expired'
          >
            <RecentlyExpiredOffers />
          </Widget>
        </Flex>
        <Flex>
          <Widget
            headerTitle={strings('owners.widget.offers.offersExpiringSoon')}
            path='dashboard/offers/expiring'
          >
            <ExpiringOffers />
          </Widget>
        </Flex>
        <BusinessLinks />
      </Stack>
    </Box>
  );
};

export default OwnerDashboard;
