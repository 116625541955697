import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';

const WidgetHeader = ({ title, headerLink }) => (
  <Flex
    align='center'
    justify='space-between'
    w='100%'
    paddingY='20px'
    paddingX='24px'
    borderBottom='1px'
    borderColor='gray.200'
  >
    <Text layerStyle='widgetHeaderText'>{title}</Text>
    {headerLink}
  </Flex>
);

WidgetHeader.propTypes = {
  title: PropTypes.string,
  headerLink: PropTypes.node,
};

export default WidgetHeader;
