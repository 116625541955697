import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Tr,
  Td,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';

const NoOffersBusinessesListItem = ({ business, strings }) => {
  const history = useHistory();
  const imageId = get(business, 'logoImage');
  const handleRowClick = (businessId) => {
    history.push(`/business/${businessId}`);
  };

  const locationString = business?.locations?.length === 1 ?
    strings('owners.widget.locationsWithoutOffers.location') :
    strings('owners.widget.locationsWithoutOffers.locations');

  return (
    <Tr _hover={{ cursor: 'pointer', bg: 'gray.50' }} onClick={() => handleRowClick(business.id)}>
      <Td width={[255, '80%']} maxWidth={[255, '80%']}>
        <Flex alignItems='center'>
          <ProfileImage imageId={imageId} name={business.name} mr='8px' />
          <Box width='80%'>
            <Text layerStyle='widgetContentText' isTruncated>{business.name}</Text>
            <Text layerStyle='widgetContentSubText' color='gray.600'>
              {`${business?.locations?.length} ${locationString}`}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>
        <Text layerStyle='widgetContentText'>{business?.plan?.name}</Text>
      </Td>
    </Tr>
  );
};

NoOffersBusinessesListItem.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoImage: PropTypes.string,
    plan: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    locations: PropTypes.arrayOf(PropTypes.shape({
      length: PropTypes.number
    }))
  }),
  strings: PropTypes.func.isRequired,
};

export default NoOffersBusinessesListItem;
