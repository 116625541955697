import useMyPlans from 'business-dashboard/src/hooks/useMyPlans';
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
  Flex,
  Spacer,
  Box
} from '@chakra-ui/react';

import client from 'business-dashboard/src/services/client';
import useProperty from 'business-dashboard/src/components/useProperty';
import propertyResolver from 'business-dashboard/src/services/propertyResolver';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import usePagedList from 'shared/src/hooks/usePagedList';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import useCheckedPlans from 'shared/src/hooks/useCheckedPlans';

import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';

import NoOffersBusinessesTable from './NoOffersBusinessesTable';
import OffersViewWrapper from '../Offers/OffersViewWrapper';

import ConditionalWrapper from '../../ConditionalWrapper';

const fetchBusinesses = ({ query, checkedPlanIds, itemsPerPage }) => {
  const property = propertyResolver();
  return client
    .properties.for(property.id)
    .businesses.list({
      query,
      fetchPlan: true,
      fetchLocations: true,
      hasLocations: true,
      enabled: true,
      locationsHasLiveOffers: false,
      locationsEnabled: true,
      limit: itemsPerPage,
      hasRoles: true,
      planIds: checkedPlanIds.length ? checkedPlanIds : undefined
    })
    .catch((error) => {
      // UI will show error message but log the error to the console for debugging
      /* eslint-disable-next-line no-console */
      console.error(error);
      return Promise.reject(error);
    });
};

const NoOffersBusinesses = ({ itemsPerPage = 5 }) => {
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard'; // TODO Improve this conditional
  const property = useProperty();
  const plans = !isDashboard && useMyPlans(property);
  const { strings } = useI18Next();

  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const [checkedPlans, handleCheckedPlans, handleUnCheckedPlan, checkedPlanIds] = useCheckedPlans(plans);
  const businessesList = usePagedList(fetchBusinesses, { query, checkedPlanIds, itemsPerPage });

  const breadcrumbs = [{
    to: '/dashboard',
    title: strings('ui.component.appSidebar.dashboard')
  }, {
    to: '/dashboard/no-offers',
    title: strings('owners.widget.locationsWithoutOffers.locationsWithoutOffers'),
    isCurrentPage: true
  }];

  const ViewWrapper = children => (
    <OffersViewWrapper
      breadcrumbs={breadcrumbs}
      plans={plans}
      checkedPlans={checkedPlans}
      handleDebounceQuery={debouncedQueryHandler}
      handleCheckedPlans={handleCheckedPlans}
      handleUnCheckedPlan={handleUnCheckedPlan}
      query={query}
      title={strings('owners.widget.locationsWithoutOffers.locationsWithoutOffers')}
    >
      {children}
    </OffersViewWrapper>
  );

  return (
    <ConditionalWrapper condition={!isDashboard} wrapper={ViewWrapper}>
      <Flex flexDir='column' mb='-1px' border={!isDashboard && '1px'} borderColor='gray.200'>
        <NoOffersBusinessesTable
          businesses={businessesList}
          itemsPerPage={itemsPerPage}
          noOffersString={strings('owners.widget.locationsWithoutOffers.emptyStateLocations')}
          scrollableDisabled={isDashboard}
        />
      </Flex>
      <Spacer />
      <Box py={6} px={isDashboard && 6} borderTop={isDashboard && '1px'} borderColor='gray.200'>
        <AutoPagination pagedList={businessesList} />
      </Box>
    </ConditionalWrapper>
  );
};

NoOffersBusinesses.propTypes = {
  itemsPerPage: PropTypes.number
};

export default NoOffersBusinesses;
